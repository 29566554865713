import React from 'react';
import Layout from 'components/Layout';
import Rainbow from 'components/modules/Rainbow';
// import SEO from '../components/site/SEO';

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 - Page not found" className="NotFound">
      <div className="Rainbow">
        <Rainbow />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
