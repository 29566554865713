/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useEffect, useRef } from 'react';

const Rainbow = () => {
  let shouldRun = true;
  const canvas = useRef(null);
  let ctx;
  let canW, canH;
  let incrementer;
  let totalIncrement = 1000;
  let strokeW = 30;
  let rainbowColors = [];
  let center, size;

  const setup = () => {
    ctx = canvas.current.getContext('2d');
    updateCanvasSize();

    incrementer = 0;
    // declare variables that need to be after instantiation
    ctx.lineWidth = strokeW * 2;
    rainbowColors[0] = 'rgb(255, 0, 0)';
    rainbowColors[1] = 'rgb(255, 127, 0)';
    rainbowColors[2] = 'rgb(255, 255, 0)';
    rainbowColors[3] = 'rgb(0, 255, 0)';
    rainbowColors[4] = 'rgb(0, 0, 255)';
    rainbowColors[5] = 'rgb(75, 0, 130)';
    rainbowColors[6] = 'rgb(148, 0, 211)';
    // start the loop
    window.requestAnimationFrame(loop);
  };

  const updateCanvasSize = () => {
    canvas.current.width = canvas.current.offsetWidth;
    canvas.current.height = canvas.current.offsetHeight;
    canW = canvas.current.width;
    canH = canvas.current.height;
    strokeW = canW / 50;
    center = { x: canW / 2, y: canH };
    size = { x: canW / 2.75, y: canH };
  };

  const loop = () => {
    incrementer++;
    ctx.clearRect(0, 0, canW, canH);
    for (let i = 0; i < rainbowColors.length; i++) {
      ctx.strokeStyle = rainbowColors[i];
      ctx.fillStyle = rainbowColors[i];
      ctx.beginPath();
      ctx.arc(
        center.x,
        center.y,
        size.x - i * strokeW * 2,
        Math.PI,
        lerp(
          Math.PI,
          Math.PI * 2,
          Math.min(incrementer / (totalIncrement / (i + 10)), 1)
        )
      );
      ctx.stroke();
    }
    if (incrementer > totalIncrement / rainbowColors.length) {
      shouldRun = false;
    }
    if (shouldRun) {
      window.requestAnimationFrame(loop);
    }
  };

  const drawCompleted = () => {
    ctx.clearRect(0, 0, canW, canH);
    updateCanvasSize();
    ctx.lineWidth = strokeW * 2;
    for (let i = 0; i < rainbowColors.length; i++) {
      ctx.strokeStyle = rainbowColors[i];
      ctx.fillStyle = rainbowColors[i];
      ctx.beginPath();
      ctx.arc(
        center.x,
        center.y,
        size.x - i * strokeW * 2,
        Math.PI,
        lerp(Math.PI, Math.PI * 2, Math.min(100 / (100 / (i + 10)), 1))
      );
      ctx.stroke();
    }
  };

  const lerp = (value1, value2, amount) => {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
  };

  const onResize = () => {
    shouldRun = false;
    drawCompleted();
  };

  useEffect(() => {
    setup();
    window.addEventListener('resize', onResize);
    return () => (shouldRun = false);
  }, [shouldRun, onResize]);

  return <canvas ref={canvas} width="600" height="600" />;
};

export default Rainbow;
